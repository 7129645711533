// Legal pages (Terms of Service)
.global-legal-content {
  // padding: 10px;
  color: $font-color;

  p > {
    ul {
      list-style: none;
      display: flex;
      padding: unset;
      justify-content: space-between;
      width: 200px;
    }
  }

  a[name='step-1'],
  a[name='step-2'],
  a[name='step-3'] {
    text-decoration: none;
    pointer-events: none;
    color: inherit;

    &:hover {
      cursor: default;
    }
  }

  a {
    color: $policy-link-color;
    font-weight: 600;
  }

  td {
    vertical-align: top;
  }

  td {
    padding-bottom: 8px;

    &:first-of-type {
      padding-right: 8px;
    }
  }
}
