@use 'sass:math';

/* Size */
$base: 16;
$r32: math.div(32, $base) * 1rem;
$r28: math.div(28, $base) * 1rem;
$r24: math.div(24, $base) * 1rem;
$r23: math.div(23, $base) * 1rem;
$r22: math.div(22, $base) * 1rem;
$r19: math.div(19, $base) * 1rem;
$r18: math.div(18, $base) * 1rem;
$r16: math.div(16, $base) * 1rem;
$r14: math.div(14, $base) * 1rem;
$r9: math.div(9, $base) * 1rem;
$r8: math.div(8, $base) * 1rem;

/* Color */
$primary-blue: #1a237e;
$primary-orange: #ff5722;
$white: #ffffff;

$text-light-color: #7d7c7c;
$text-error-color: #a83131;

$wecome-heading: #fff;

$bg-welcome: #c4c4c4;
$bg-step-inactive: $bg-welcome;
$bg-register-form-steps: #fafafa;
$bg-register-form-steps-border: #ebebeb;
$bg-timeStamp: rgba(255, 255, 255, 0.6);
$btn-login: #00639a;
$button--secondary: #00963f;

$button-orange: $primary-orange;
$button-orange-dark: #e84e1d;
$button-orange-hover: #ff4409;
$button-orange-dark-hover: #d94516;

$button-blue: $primary-blue;
$button-blue-dark: #0b1259;
$button-blue-hover: #151c6b;
$button-blue-dark-hover: #0b1154;

$button-red: #a83131;
$button-red-hover: #8d2929;

$button-default: #828282;
$button-default-hover: #737373;
$font-color: #414042;
$font-color-highlighted: #555;
$font-color-welcome: #656565;
$font-color-timeStamp: #000000;
$font-color-tiles: #454545;
$toggle-switch-color: #2ea345;
$myuplink-text-color: #414042;
$link-basic-color: #2151c5;
$policy-link-color: #1a237e;
$spinner-dark-color: #414042;

$checkbox-blue-background: $primary-blue;
$checkbox-blue-forground: $white;

$radio-service-partner-select: #bfbfbf;
$radio-service-partner-select-active: $primary-orange;

$input-placeholder-text-color: #585858;
$works-with-border: #b7aeae;
$signed-out-background: #dfdfdf;
$workWithBrandBorder: #b7aeae;
$gray-border: #dddddd;

$space-md: 16px 40px;
$space-lg: 24px 60px;
$space-sm: 12px 24px;
$font-size-md: 1.2rem;
$font-size-lg: 1.6rem;
$font-size-sm: 0.8rem;
$font-size-xl: 32px;
$disabled-opacity: 0.5;
$active-opacity: 0.7;
$btn-min-width: 160px;

/* Colors with gradient */
$primary-blue-with-linear-gradient: linear-gradient($button-blue, $button-blue-dark);
$primary-blue-with-linear-gradient-hover: linear-gradient($button-blue-hover, $button-blue-dark-hover);
$primary-orange-with-linear-gradient: linear-gradient($button-orange, $button-orange-dark);
$primary-orange-with-linear-gradient-hover: linear-gradient(#ff4105, #d54315);

$icon-background-color: $white;
