.hamburgerMenuButton {
  position: relative;
  top: 0.5em;
  left: 0px;
  height: 2.4em;

  &__line {
    height: 3px;
    width: 34px;
    background: black;
    transform: none;
    transition: all 0.2s ease;

    &--top {
      transform-origin: top left;
      margin-bottom: 7px;
    }

    &--middle {
      opacity: 1;
    }

    &--bottom {
      transform-origin: bottom right;
      margin-top: 7px;
    }
  }

  &--open {
    .hamburgerMenuButton__line {
      &--top {
        margin: 0;
        transform: rotate(45deg) translateX(2px) translateY(-6px);
      }

      &--middle {
        opacity: 0;
        margin: 0 auto;
      }

      &--bottom {
        margin: 0;
        transform: rotate(-45deg) translateX(4px) translateY(-10px);
      }
    }
  }
}
