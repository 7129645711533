@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/media-queries.scss';

.desktopMenu {
  display: block;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: inherit;
  width: 100%;

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
  }

  &__item {
    position: relative;
    cursor: pointer;
    width: auto;
    padding: 0 2rem;
    @include label();
    font-size: 1.25rem;
    line-height: 4.5rem;
    display: inline-block;
    margin-left: 0;

    &--languageSelector {
      cursor: auto;
    }

    &--right {
      margin-left: auto;
    }

    &--right ~ &--right {
      margin-left: 0;
    }

    .caret {
      margin-left: 0.5rem;
    }

    &:hover {
      .desktopSubMenu {
        display: block;
      }
    }
  }

  &__itemLink {
    &--icon {
      display: inline-block;
      height: 2.5rem;
      width: 2.5rem;
      vertical-align: middle;
      background-repeat: no-repeat;
      color: #454545;
    }
    & > span:first-child {
      line-height: 1;
    }
  }

  &__itemLinkIcon {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    background: yellow;
  }
}

.desktopSubMenu {
  display: none;
  position: absolute;
  left: 0;
  top: 4.4rem;
  min-width: 100%;
  z-index: 25;
  padding-bottom: 1rem;
  width: 200%;

  &__list {
    float: left;
    display: flex;
    flex-direction: column;
    background: white;
    padding-bottom: 1rem;
    box-shadow: 0px 10px 10px 0 rgba(50, 50, 50, 0.15);
  }

  .desktopSubMenu__link {
    padding: 0 2rem;
    @include label();
    line-height: 2rem;
    position: relative;
    color: #414042;
    justify-content: left;

    span {
      font-weight: inherit;
      line-height: inherit;
    }

    &-disabled {
      color: #d8d8d8;
      cursor: default;
      padding: 0 2rem;
      @include label();
      line-height: 2rem;
      position: relative;
    }
  }

  &--expand-left {
    right: 0;
    left: auto;
    top: 4.5rem;
  }

  &__storeLink {
    padding: 0 2rem;
    @include label();
    line-height: 1rem;
    text-decoration: none !important;
    color: #414042 !important;
    width: 220%;
  }

  &__iotStoreLink {
    height: auto;

    &:hover {
      text-decoration: none;
      color: #414042;
    }

    &-disabled {
      color: #d8d8d8;
      height: auto;
      &:hover {
        text-decoration: none;
        color: #d8d8d8;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .desktopMenu {
    display: none;
  }
}
