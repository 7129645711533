@import '../../../styles/mixins.scss';
@import '../../../styles/media-queries.scss';
@import '../../../styles/variables.scss';

.footer {
  &-container {
    position: relative;
    padding: 1.5em 1em;
    color: #414042;
    box-shadow: 0px -5px 15px -5px rgba(0, 0, 0, 0.2);

    & .row {
      display: flex;
      justify-content: space-between;
      @include contentLayoutWrapper();
    }
  }

  &-copyright {
    color: #414042;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 20px;
    padding-right: 18px;
    padding-top: 6px;
    text-align: right;

    &.row {
      justify-content: flex-end;
    }
  }

  &-premium {
    text-align: center;
    width: 100%;

    &.row {
      border-bottom: 1px solid #858585;
      margin-bottom: 1em;
      padding-bottom: 3em;
      padding-top: 2rem;
    }
  }

  &-block {
    width: 30%;
    min-width: 400px;
  }

  &-text {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #414042;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    .download-heading {
      font-weight: bold;
      padding-top: 8px;
      padding-bottom: 18px;
    }
  }

  &-badges {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;

    & img {
      width: 160px;
      margin: 1rem 0 1rem;
    }

    & a:last-child {
      margin-left: 1em;
    }
  }
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 68%;
  margin-top: 16px;
  margin-right: 160px;
  gap: 60px;

  & .footer-link {
    margin-right: 16px;

    & a {
      color: #414042;
      @include label();
      line-height: 2rem;
    }

    &-text {
      font-size: 1.2rem;
      line-height: 2rem;
      margin-right: 4rem;
      color: #414042;
      font-weight: bold;
      justify-content: space-between;
      padding-bottom: 12px;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: $smallDesktop) {
  .footer-container {
    padding: 2em 1rem;

    .row {
      display: block;
      padding: 0 1em;
      padding-bottom: 2em;

      .footer-links {
        width: 100%;

        &-item {
          &:first-child {
            justify-content: center;

            .footer-link {
              margin: 0 2rem;
            }
          }

          justify-content: space-between;
          width: 100%;
          padding-bottom: 1rem;
        }
      }
    }

    .footer-block {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 2rem 0;
    }

    .footer-link {
      text-align: left;
      margin-right: 0;

      a {
        display: inline-block;
        height: 40px;
        width: 100%;
        line-height: 40px;
      }
    }
  }

  .footer-badges {
    justify-content: center;
    margin-top: 0.5em;

    img {
      width: 160px;
    }
  }

  .footer-copyright {
    text-align: center;
  }

  .footer-premium .row {
    display: flex;
    padding: 16px 0;
  }
}

@media only screen and (max-width: $tablet) {
  .footer-container .row {
    padding: 0;
    .footer-links {
      flex-direction: column;
    }

    .footer-links-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        font-size: 18px;
      }
    }

    .footer-link {
      margin: 0;
    }

    .footer-link-text {
      margin-right: 0;
      font-size: 18px;
      padding-bottom: 1.5rem;
    }

    .footer-block {
      margin-top: 0;
    }

    .footer-copyright {
      text-align: center;
    }

    .footer-text {
      font-size: 18px;
      line-height: 3rem;
    }

    .footer-badges {
      a {
        margin: 1rem;
      }
    }
  }

  .footer-premium.row {
    display: flex;
    padding: 16px 0;
  }
}
