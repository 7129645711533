section#main-content {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100vh - 45em - 72px);
}

.status-banner {
  width: 100%;
  padding: 15px;
  background-color: #000;
  color: #fff;
  text-align: center;
}

.grey-bg {
  background-color: #f4f4f4;
}
