@import '../../../../../styles/media-queries.scss';

.hamburgerMenu {
  position: absolute;
  top: 4.5rem;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  color: #fafafa;
  transition: height 0.3s ease;
  z-index: 11;
  border-image: linear-gradient(white, black) 22;
  border-width: 0;
  border-top-color: gray;
  border-top-style: solid;
  border-top-width: 8px;

  &__list {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-image: linear-gradient(black, white) 11;
    border-width: 0;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    width: 100%;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      right: 0px;
      top: -27px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 27px 10px;
      border-color: transparent;
      border-bottom-color: white;
      z-index: 9999;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0px;
      top: -34px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 34px 15px;
      border-color: transparent;
      border-bottom-color: rgba(192, 192, 192, 0.11);
      z-index: 1;
    }
  }

  @media only screen and (max-width: $tablet) {
    &__list {
      padding-top: 20px;

      &:before {
        right: 0;
      }

      &:after {
        right: 0;
      }
    }
  }

  @media only screen and (max-width: $mobile) {
    top: 4.5rem;
    padding-bottom: 2rem;

    &:after {
      right: 14vw;
    }
    &:before {
      right: 15vw;
    }
  }
}
