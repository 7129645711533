@import '../../../../styles/media-queries.scss';

.customLanguageSelector {
  display: inline-block;

  #language-selector {
    padding: 0;
    margin: 0;
  }

  &.welcomePage__menuLink {
    opacity: 1;
  }

  #language-globe {
    display: inline-block;
    font-size: 0.85rem;
    line-height: 0.85rem;
    margin: 2px 0 0 0px;
  }

  #language-selector {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    box-shadow: none;
    color: #414042;
    cursor: pointer;
    display: inline-block;
    float: none;
    font-size: 0.85rem;
    font-weight: 400;
    height: auto;
    width: auto;
  }
}

@media screen and (max-width: $tablet) {
  .customLanguageSelector {
    #language-selector,
    #language-globe {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
    #language-globe {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: $mobile) {
  .customLanguageSelector {
    #language-selector,
    #language-globe {
      font-size: 1.2rem;
      line-height: 1rem;
    }
  }
}
