@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.welcomePage {
  position: relative;
  background-color: $signed-out-background;
  background-size: cover;

  .container-fluid {
    margin: 0;
    padding: 0;
  }

  .lang-mob {
    display: none;
  }

  .form {
    &__link {
      font-size: $r16;
      color: $primary-blue;

      :hover {
        text-decoration: underline;
      }
    }

    &__paragraph {
      margin: 0rem;
    }
  }

  .fullHeight {
    height: 100%;
    background-color: $white;
  }

  &__wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    overflow-x: hidden;

    & .status-banner {
      width: 100%;
      padding: 15px;
      background-color: #000;
      color: $white;
      text-align: center;
    }
  }

  &__src {
    width: 100%;
    max-width: 1200px;
    position: absolute;
    top: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    opacity: 0.5;
  }

  &__logoWrapper {
    position: absolute;
    left: 48px;
    top: 32px;
    z-index: 9999;
  }

  &__logo {
    max-height: 40px;
    cursor: pointer;
  }

  &__contentWrapper {
    max-width: 1500px;
    margin: 0px auto;
    padding: 0 32px 32px 0;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .legalContent__page {
    width: 100%;
    background-color: $white;

    &_height {
      background-color: $white;
    }

    &__header__wrapper {
      width: 100%;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);
      position: relative;
    }

    &__header {
      height: 87px;
      padding: 16px 48px 16px 62px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .customLanguageSelector #language-selector {
        font-size: $r16;
        appearance: menulist;
      }
    }

    &__content {
      padding: 1.25rem 3rem 2rem 3.88rem;
    }

    &__header,
    &__content {
      max-width: 1500px;
      margin: 0 auto;
    }
  }

  .padding-none {
    padding: 0;
    max-width: 100%;
  }

  &__slideshow {
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58%;
    padding: 48px;

    .works-with-brands-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .smart-device-logo-wrapper {
        display: flex;
        justify-content: center;
        padding-bottom: 5px;
      }
    }

    .brand-list-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      justify-content: space-between;

      .brand-logo-wrapper {
        margin: 0;
      }
    }

    .device-logo {
      margin: 5px;
    }

    .brand-logo {
      width: 100%;
      height: 100%;
    }

    .device-logo,
    .brand-logo {
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      background: $white;
      aspect-ratio: 1/1;
      padding: 4px;
    }
  }

  .fullWidth {
    width: 100%;
  }

  .footer-container {
    .footer-block {
      padding-right: 32px;

      .footer-text {
        padding: 16px 0 12px 0;

        .download-heading {
          padding-top: 0px;
        }
      }
    }

    .footer-links {
      padding-left: 48px;

      .footer-link-text {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  &__formWrapper {
    order: 2;
    width: 42%;
    height: 100%;
    background-color: white;
    padding: 40px 30px 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .welcome-page-form {
      margin: 0 auto;
      max-width: none;

      .form__heading {
        padding-top: 20px;
      }

      .inputComponent {
        &__placeholder {
          color: $input-placeholder-text-color;
          font-weight: 400;
          font-size: $r19;

          &.focused {
            font-size: $r9;
          }
        }

        &__placeholder__label {
          margin: 0;
        }

        &__field {
          border-radius: 6px;
          min-width: auto;
        }
      }

      .checkbox-wrapper {
        padding: 0;
        margin: 0;

        .checkboxComponent {
          font-weight: 400;

          input:checked + label:after {
            width: 7px;
            height: 12px;
            top: auto;
            left: 6px;
            border-width: 0 3px 3px 0;
          }

          &__label {
            margin: 0;
            text-decoration: none;
          }

          &__label::before {
            padding: 7px;
          }
        }

        .checkboxComponent__label[for='acceptedAgreement'] div,
        .checkboxComponent__label[for='acceptedPrivacyPolicy'] div {
          font-weight: 400;
          cursor: default;
        }

        .checkboxComponent__label[for='acceptedAgreement'] div button,
        .checkboxComponent__label[for='acceptedPrivacyPolicy'] div button {
          font-weight: bold;
          text-decoration: underline;
          border: none;
          background-color: transparent;
          padding: 0;
        }

        &.checkboxComponent--error {
          position: relative;
        }
      }

      .button-wrapper--large {
        .form__paragraph {
          margin: 0;
        }
      }
    }

    .register-form__wrapper {
      .form-steps__step {
        padding: 0 29px;
      }

      .headline {
        max-width: 370px;
        margin: auto;
      }

      .back-to-login-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: $r16;
        font-weight: 700;
        color: $text-light-color;

        .login-link {
          color: $primary-orange;
          cursor: pointer;
        }
      }
    }
  }

  &__formWrapperContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    //overflow-y: auto;

    .form {
      margin: 0 auto;
      max-width: 370px;
    }
  }

  &__menuWrapper {
    text-align: center;
    margin-bottom: 24px;
  }

  &__menuLink {
    @include label();
    font-size: 0.85rem;
    text-decoration: none;
    font-weight: 400;
    margin-right: 16px;
    color: #414042;
    display: inline-block;

    &:first-of-kind {
      margin-left: 0;
    }

    &.active {
      opacity: 1;
      font-weight: 500;
      text-decoration: underline;
      color: #1a237e;
    }

    &:hover {
      color: #7d7c7c;
    }
  }

  .form__login-middle-section-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .form__link {
      padding: 0;
      color: $primary-blue;
      font-size: $r14;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }

    .checkbox-wrapper {
      margin: 0;
      padding: 0;

      .checkboxComponent {
        &__label {
          margin: 0;

          &::before {
            margin-right: 10px;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            padding: 0;
          }
        }

        input:checked + label::before {
          background-color: $checkbox-blue-background;
          border: 0;
        }

        input:checked + label::after {
          top: 4px;
          left: 6px;
          width: 4px;
          height: 9px;
          border-width: 0px 2px 2px 0;
          transform: rotate(45deg);
          border-color: $checkbox-blue-forground;
        }

        &__text {
          font-weight: 400;
        }
      }
    }
  }

  .button-wrapper--large {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: $r19;
    font-weight: 400;

    &.login-cta {
      margin: 0;
    }

    .button.button--primary,
    .button.button--secondary {
      margin: 0;
    }

    & > span {
      text-align: center;
      font-size: $r14;
    }
  }
}

@media screen and (max-width: $tablet) {
  .welcomePage {
    &__contentWrapper {
      flex-direction: column;
      height: auto;
      padding: 0;
    }

    .form {
      margin-top: 32px;
    }

    .footer-container {
      .footer-block {
        padding-right: 0;
      }

      .footer-links {
        padding-left: 0;
      }
    }

    &__formWrapper {
      order: 1;
      width: 100%;
      height: auto;
      padding: 32px 48px 48px;
    }

    &__formWrapperContent {
      align-items: center;
    }

    &__slideshow {
      order: 2;
      height: auto;
      width: 100%;
      padding: 80px 48px;
    }

    &__menuWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &__menuLink {
      font-size: 1.5rem;
      line-height: 2.5rem;
      display: block;
      margin-top: 8px;
    }

    &__logo {
      width: 180px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .welcomePage {
    .form {
      max-width: none;
    }

    &__logoWrapper {
      order: 1;
      position: initial;
      display: flex;
      justify-content: space-between;
      padding: 32px;
      background-color: $white;
    }

    .lang-mob {
      display: flex;

      #language-selector {
        padding: 12px;
      }
    }

    .lang-desk {
      display: none;
    }

    &__menuWrapper {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__menuLink {
      order: 2;

      &.customLanguageSelector {
        order: 1;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
      }
    }

    &__formWrapper {
      order: 2;
      width: 100%;
      height: auto;
      padding: 32px;

      .inputComponent {
        &__field {
          min-width: auto;
        }
        &__placeholder__label {
          margin-top: 1rem;
        }
      }
    }

    &__slideshow {
      order: 3;
      min-width: auto;
      padding: 0;
    }
  }
}
